<template>
    <TabContentView
        v-model="currentProcess"
        :tabs="tabs"
        :slug="currentProcess"
        :url-param="param"
        class="pt-[30px]"
    >
        <template #tab-heading>
            <h3 v-if="data.selectionHeading" class="mb-4">
                {{ data.selectionHeading }}
            </h3>
        </template>
        <template #content>
            <div v-for="process in data.processes" :key="process.slug">
                <ProcessView
                    v-if="process.slug === currentProcess"
                    :data="process"
                />
            </div>
        </template>
        <template #cta>
            <CtaCard
                v-if="data.cta"
                class="mt-4"
                :link-url="data.cta.link?.url"
                :link-label="data.cta.link?.label"
                :text="data.cta.text"
                :heading="data.cta.heading"
            />
        </template>
    </TabContentView>
</template>

<script setup lang="ts">
import ProcessSelectType from '~/types/ProcessSelectType';
import ProcessView from '~/components/page-building/process-select/ProcessView.vue';
import { ref } from 'vue';
import { slugify } from '~/utils/helpers';
import TabContentView from '~/components/page-building/components/tab-content-view/TabContentView.vue';
import TabContentViewTabType from '~/types/TabContentViewTabType';
import ProcessType from '~/types/ProcessType';
import CtaCard from '~/components/page-building/components/CtaCard.vue';

const route = useRoute();
const param = ref<string>('processType');

const props = defineProps<{
    data: ProcessSelectType;
}>();

const tabs = computed<TabContentViewTabType[]>(() => {
    return props.data.processes.map((process: ProcessType) => {
        return {
            image: process.selectImage,
            label: process.selectLabel,
            slug: slugify(process.selectLabel),
        } as TabContentViewTabType;
    });
});

const urlParam = computed<string | null>(() => {
    return Object.keys(route.query).length > 0 &&
        typeof route.query[param.value] === 'string' &&
        route.query[param.value] !== ''
        ? route.query[param.value]
        : null;
});

const preSelectedTab = computed<ProcessType | undefined>(() => {
    return props.data.processes.find(
        (item: ProcessType) => item.slug === urlParam.value,
    );
});

const currentProcess = ref<string | null>(
    preSelectedTab.value
        ? preSelectedTab.value.slug
        : props.data.processes[0].slug,
);
</script>
